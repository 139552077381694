import dayjs, { extend } from 'dayjs'
import relativeTimePlugin from 'dayjs/plugin/relativeTime'
import { useQuery } from '@tanstack/vue-query'
import { v4 as uuidv4 } from 'uuid'

export { inflect } from 'inflection'

export {
  useQuery,
  useMutation,
  useInfiniteQuery,
  useQueryClient
} from '@tanstack/vue-query'

export const LONG_DATE_FORMAT = 'dddd, D MMMM YYYY'
export const SHORT_DATE_FORMAT = 'MMM D, YYYY'
export const DATE_TIME_FORMAT = 'dddd, D MMMM YYYY - hh:mm A'

export const PLACEHOLDER_IMAGE = 'https://via.placeholder.com/150'

extend(relativeTimePlugin)

export const formatDate = (date: string, format: 'l' | 's' | 'dt') => {
  const formatMap = {
    l: LONG_DATE_FORMAT,
    s: SHORT_DATE_FORMAT,
    dt: DATE_TIME_FORMAT
  }
  return dayjs(date).format(formatMap[format])
}

export const relativeTime = (date: string) => {
  return dayjs(date).fromNow()
}

export const toPercentage = (value: number) => (value * 100).toFixed(1) + '%'

export const promisify = <T>(val: T) => val

export const enumKeys = <E>(enu: E) =>
  Object.keys(enu as any).filter((key) => isNaN(Number(key))) as (keyof E)[]

export const beforeUnloadListener = (event: BeforeUnloadEvent) => {
  event.preventDefault()
  return (event.returnValue = '')
}

export const exportToWordDoc = (docElement: HTMLElement, title: string) => {
  const docHtml = docElement.innerHTML

  const html = `<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>${title}</title></head><body>${docHtml}</body></html>`

  const blob = new Blob(['\uFEFF', html], {
    type: 'application/msword'
  })

  // Specify link url
  const url = URL.createObjectURL(blob)

  // Specify file name
  const fileName = `${title}.doc`

  downloadFile(url, fileName)
}

export const downloadFile = (url: string, fileName: string) => {
  // Create download link element
  const downloadLink = document.createElement('a')

  document.body.appendChild(downloadLink)

  // Create a link to the file
  downloadLink.href = url

  // Setting the file name
  downloadLink.download = fileName

  // triggering the function
  downloadLink.click()

  document.body.removeChild(downloadLink)
}

export const useCountries = () => {
  const { axios } = useApi()
  const COUNTRIES_API_URL = `${window.location.protocol}//${window.location.host}/resources/countries.json`

  return useQuery({
    queryFn: async () => {
      const response = await axios.get<CountriesResponse>(COUNTRIES_API_URL)
      const countries = response.data
      countries.EP = 'Europe'
      countries.WO = 'Global'
      return countries
    },
    queryKey: ['countries']
  })
}

export const useUnsavedChanges = <F extends (...args: any) => any>(
  saver: F
) => {
  onBeforeUnmount(() => {
    removeEventListener('beforeunload', beforeUnloadListener, { capture: true })
  })

  return async (...args: Parameters<F>): Promise<ReturnType<F>> => {
    addEventListener('beforeunload', beforeUnloadListener, { capture: true })
    const result = await saver(...(args as any))
    if (result) {
      removeEventListener('beforeunload', beforeUnloadListener, {
        capture: true
      })
    }
    return result
  }
}

export const capitalizeFirstLetter = (string: string) =>
  string.charAt(0).toUpperCase() + string.slice(1)

export const getFlag = (countryCode: string) => {
  const flags: Record<string, string> = {
    EP: '/images/flags/europe.png',
    WO: '/images/flags/globe.png'
  }
  return (
    flags[countryCode] ?? `/images/flags/4x3/${countryCode.toLowerCase()}.svg`
  )
}

export const randomUUID = uuidv4

export const randomIntFromInterval = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

export const shuffleArray = (array: unknown[]) => {
  let currentIndex = array.length
  let randomIndex: number

  // While there remain elements to shuffle.
  while (currentIndex > 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex--

    // And swap it with the current element.
    ;[array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex]
    ]
  }

  return array
}
